// debugger
//// ================================ Imports ======================================
//scss
// import 'bootstrap/dist/css/bootstrap.css';
// import 'nouislider/dist/nouislider.css';

import '../scss/index.scss';
import * as bootstrap from 'bootstrap';

// import bootstrap from 'bootstrap'

//js
// import $ from 'jquery';
// import { Fancybox, Carousel, Panzoom } from '@fancyapps/ui';
// import IMask from 'imask';
// import Choices from 'choices.js';
// import autoComplete from '@tarekraafat/autocomplete.js';
// import validate from 'validate.js';
// import ApexCharts from 'apexcharts';
// import { rippleEffect, Ripple } from 'data-ripple';
// import noUiSlider from 'nouislider';
// import Scrollbar from 'smooth-scrollbar';

import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';


// import * as Popper from "@popperjs/core"
// import './calendar.js'

//// ================================ Code ======================================




const body = document.querySelector('body');
const burger = document.querySelector('.js-burger');
const header = document.querySelector('.js-header');
burger?.addEventListener('click', () => {
  burger.classList.toggle('burger--active');
  header.classList.toggle('header--active');
  if (burger.classList.contains('burger--active')) {
    body.style.overflow = 'hidden';
  } else {
    body.style.overflow = 'inherit';
  }
});


const videosContainer = document.querySelectorAll('.js-video-container');
// const dinamicUrl = 'https://www.youtube.com/embed/7PIji8OubXU?autoplay=1';

if (videosContainer) {
  videosContainer.forEach((videoConainer) => {
    let playVideo = false;
    const video = videoConainer.querySelector('.js-video');
    const play = videoConainer.querySelector('.js-play');
    const videoContent = videoConainer.querySelector('.video__content');
    let dinamicUrl = video.dataset.src;
    // console.log(video.dataset.src);

    play.addEventListener('click', () => {
      playVideo = true;
      videoContent.classList.toggle('video__content--active');
      video.src = dinamicUrl;
    });

    body.addEventListener('click', (e) => {
      if (!e.target.closest('.video__content') && playVideo) {
        videoContent.classList.toggle('video__content--active');
        video.src = '';
        playVideo = false;
      }
    });
  });
}

const slides = new Swiper('.slides', {
  modules: [Navigation, Pagination],
  slidesPerView: 1,
  spaceBetween: 14,
  navigation: {
    nextEl: '.slide__btn--next',
    prevEl: '.slide__btn--prev',
  },
  pagination: {
    el: '.slide__pagination',
  },
  breakpoints: {
    577: {
      slidesPerView: 2,
      spaceBetween: 12,
    },
    768: {
      slidesPerView: 2.2,
      spaceBetween: 12,
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 24,
    },
    1440: {
      slidesPerView: 3,
      spaceBetween: 24,
    }
  },
});

const headerClose = document.querySelector('.js-header-btn');
const flexLeft = document.querySelector('.js-flex__left');
const flexRight = document.querySelector('.js-flex__right');
// const headerItems = document.querySelectorAll('.js-header-item');
const headerRight = document.querySelector('.js-header__right');
let isOpenBar;



if (localStorage.getItem('isOpenBar')) {
  isOpenBar = JSON.parse(localStorage.getItem('isOpenBar'));
} else {
  isOpenBar = false;
  localStorage.setItem('isOpenBar', isOpenBar);
}

if (isOpenBar) {
  header?.classList.add('header--active');
  flexLeft?.classList.add('flex__left--active');
} else {
  header?.classList.remove('header--active');
  flexLeft?.classList.remove('flex__left--active');
}

if (window.innerWidth < 768) {
  header?.classList.remove('header--active');
  flexLeft?.classList.remove('flex__left--active');
}

if (flexLeft) {
  if (header?.classList.contains('header--active') && headerRight?.classList.contains('header__right--active')) {
    // flexLeft.style.width = 329 + 'px';
    flexLeft.style.width = 385 + 'px';
  } else if (!header?.classList.contains('header--active') && headerRight?.classList.contains('header__right--active')) {
    flexLeft.style.width = 260 + 320 + 'px';
  } else if (header?.classList.contains('header--active') && !headerRight?.classList.contains('header__right--active')) {
    // flexLeft.style.width = 11 + 'px';
    flexLeft.style.width = 64 + 'px';
  } else if (!header?.classList.contains('header--active') && !headerRight?.classList.contains('header__right--active')) {
    if (window.innerWidth < 768) {
      flexLeft.style.width = '100%';
    } else {
      flexLeft.style.width = 260 + 'px';
    }
  }
}


headerClose?.addEventListener('click', () => {
  header?.classList.toggle('header--active');
  flexLeft?.classList.toggle('flex__left--active');

  isOpenBar = !isOpenBar;
  localStorage.setItem('isOpenBar', isOpenBar);

  if (header?.classList.contains('header--active') && headerRight?.classList.contains('header__right--active')) {
    // flexLeft.style.width = 329 + 'px';
    flexLeft.style.width = 385 + 'px';
  } else if (!header?.classList.contains('header--active') && headerRight?.classList.contains('header__right--active')) {
    flexLeft.style.width = 260 + 320 + 'px';
  } else if (header?.classList.contains('header--active') && !headerRight?.classList.contains('header__right--active')) {
    // flexLeft.style.width = 11 + 'px';
    flexLeft.style.width = 64 + 'px';
  } else if (!header?.classList.contains('header--active') && !headerRight?.classList.contains('header__right--active')) {
    flexLeft.style.width = 260 + 'px';
  }
});

window.addEventListener('resize', () => {
  if (flexLeft) {
    if (window.innerWidth < 768) {
      flexLeft.style.width = '100%';

      isOpenBar = false;
      localStorage.setItem('isOpenBar', isOpenBar);
      header?.classList.remove('header--active');
      flexLeft?.classList.remove('flex__left--active');
    } else {
      if (headerRight && headerRight?.classList.contains('header__right--active')) {
        if (header?.classList.contains('header--active')) {
          flexLeft.style.width = 64 + 320 + 'px';
        } else {
          flexLeft.style.width = 260 + 320 + 'px';
        }
      } else {
        if (header?.classList.contains('header--active')) {
          flexLeft.style.width = 64 + 'px';
        } else {
          flexLeft.style.width = 260 + 'px';
        }
      }
    }
  }
});

if (flexLeft) {
  if (window.innerWidth < 768) {
    if (headerRight && headerRight?.classList.contains('header__right--active')) {
      flexLeft.style.width = '100%';
    }
  } else {
    if (headerRight && headerRight?.classList.contains('header__right--active')) {
      if (header?.classList.contains('header--active')) {
        flexLeft.style.width = 64 + 320 + 'px';
      }
      // else {
      //   flexLeft.style.width = 260 + 320 + 'px';
      // }
    } else {
      if (header?.classList.contains('header--active')) {
        flexLeft.style.width = 64 + 'px';
      } else {
        flexLeft.style.width = 260 + 'px';
      }
    }
  }
}

// headerItems?.forEach((headerItem) => {
//   headerItem.addEventListener('click', () => {
//     headerRight?.classList.toggle('header__right--active');
//     if (flexLeft) {
//       if (headerRight?.classList.contains('header__right--active')) {
//         if(header?.classList.contains('header--active')) {
//           flexLeft.style.width = 64 + 320 + 'px';
//         } else {

//           flexLeft.style.width = 260 + 320 + 'px';
//         }
//       } else {
//         if(header?.classList.contains('header--active')) {
//           flexLeft.style.width = 64 + 'px';
//         } else {
//           flexLeft.style.width = 260 + 'px';
//         }
//       }
//     }
//   });
// });

let jsonHeader;

if (window.Asteq) {
  jsonHeader = window.Asteq.Header.data;
} else {
  jsonHeader = [
    {
      id: 1,
      name: 'Головной мозг',
      level: 1,
      count: 134,
      isOpen: true,
      array: [
        {
          id: 1,
          name: 'Аномалии развития',
          level: 2,
          count: 30,
          isOpen: true,
          array: [
            {
              id: 1,
              name: 'Нарушения органогенеза',
              level: 3,
              count: 8,
              link: '/',
              array: [],
            },
            {
              id: 2,
              name: 'Нарушения органогенеза',
              level: 3,
              count: 8,
              link: '/',
              array: [],
            },
            {
              id: 3,
              name: 'Нарушения органогенеза',
              level: 3,
              count: 8,
              link: '/',
              array: [],
            },
            {
              id: 4,
              name: 'Нарушения органогенеза',
              level: 3,
              count: 8,
              link: '/',
              array: [],
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: 'Брюшная полость',
      level: 1,
      count: 30,
      array: [
        {
          id: 1,
          name: 'Аномалии развития',
          level: 2,
          count: 8,
          array: [
            {
              id: 1,
              name: 'Нарушения органогенеза',
              level: 3,
              count: 8,
              link: '/',
              array: [],
            },
          ],
        },
        {
          id: 2,
          name: 'Аномалии развития2',
          level: 2,
          count: 8,
          array: [
            {
              id: 1,
              name: 'Нарушения органогенеза2',
              level: 3,
              count: 8,
              link: '/',
              array: [],
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: 'Брюшная полость',
      level: 1,
      count: 30,
      array: [
        {
          id: 1,
          name: 'Аномалии развития',
          level: 2,
          count: 8,
          array: [
            {
              id: 1,
              name: 'Нарушения органогенеза',
              level: 3,
              count: 8,
              link: '/',
              array: [],
            },
          ],
        },
      ],
    },
  ];
}

const rightCase = document.querySelector('.js-right-case');

let htmlJson = (item, containerCopy, indexElement) => {
  const mainBlock = document.createElement('div');
  mainBlock.classList = 'header__right-container';
  const elem = document.createElement('div');
  if (item.level == 1) {
    elem.innerHTML = `
    <svg width="20" height="20">
      <use xlink:href="/assets/icons/sprite.svg#level1"></use>
    </svg>
    ${item.name}
     <div class='header__right-count'>${item.count}</div>
     `;
  } else if (item.level == 2) {
    elem.innerHTML = `
    <svg width="20" height="20">
      <use xlink:href="/assets/icons/sprite.svg#level2"></use>
    </svg>
    ${item.name}
     <div class='header__right-count'>${item.count}</div>
     `;
  }
  if (item.level == 3) {
    elem.innerHTML = `
    <svg width="20" height="20">
      <use xlink:href="/assets/icons/sprite.svg#level3"></use>
    </svg>
    ${item.name}
     <div class='header__right-count'>${item.count}</div>
     `;
  }

  if (item.isOpen) {
    elem.classList = 'header__right-info';
  } else {
    elem.classList = 'header__right-info collapsed';
  }

  const container = document.createElement('div');
  container.classList = 'header__right-container';
  const containerCollaps = document.createElement('div');
  containerCollaps.classList = 'header__right-container-collapse';
  if (item.array) {
    elem.setAttribute('data-bs-toggle', 'collapse');
    elem.setAttribute('href', `#headerAccardion${item.level}${indexElement}`);
    elem.setAttribute('role', 'button');
    elem.setAttribute('aria-expanded', 'false');
    elem.setAttribute('aria-controls', `#headerAccardion${item.level}${indexElement}`);
    container.setAttribute('id', `headerAccardion${item.level}${indexElement}`);
    if (item.isOpen) {
      container.classList = 'collapse multi-collapse show';
    } else {
      container.classList = 'collapse multi-collapse';
    }

    mainBlock.appendChild(elem);
    mainBlock.appendChild(container);
    container.appendChild(containerCollaps);
    if (item.level == 1) {
      rightCase.appendChild(mainBlock);
    } else {
      containerCopy.appendChild(mainBlock);
    }
    htmlJson(item.array, containerCollaps, indexElement);
  } else {
    item.forEach((child) => {
      const mainBlock = document.createElement('div');
      mainBlock.classList = 'header__right-container';
      const elem = document.createElement('div');
      if (child.level == 1) {
        if (child.link) {
          elem.innerHTML = `
          <a class='header__right-link' href='${child.link}'>
          <svg width="20" height="20">
            <use xlink:href="/assets/icons/sprite.svg#level3"></use>
          </svg>
          ${child.name}
          <div class='header__right-count'>${child.count}</div>
          </a>
         `;
        } else {
          elem.innerHTML = `
            <svg width="20" height="20">
              <use xlink:href="/assets/icons/sprite.svg#level3"></use>
            </svg>
            ${child.name}
            <div class='header__right-count'>${child.count}</div>
           `;
        }
      } else if (child.level == 2) {
        if (child.link) {
          elem.innerHTML = `
          <a class='header__right-link' href='${child.link}'>
          <svg width="20" height="20">
            <use xlink:href="/assets/icons/sprite.svg#level3"></use>
          </svg>
          ${child.name}
          <div class='header__right-count'>${child.count}</div>
          </a>
         `;
        } else {
          elem.innerHTML = `
            <svg width="20" height="20">
              <use xlink:href="/assets/icons/sprite.svg#level3"></use>
            </svg>
            ${child.name}
            <div class='header__right-count'>${child.count}</div>
           `;
        }
      }
      if (child.level == 3) {
        if (child.link) {
          elem.innerHTML = `
          <a class='header__right-link' href='${child.link}'>
          <svg width="20" height="20">
            <use xlink:href="/assets/icons/sprite.svg#level3"></use>
          </svg>
          ${child.name}
          <div class='header__right-count'>${child.count}</div>
          </a>
         `;
        } else {
          elem.innerHTML = `
            <svg width="20" height="20">
              <use xlink:href="/assets/icons/sprite.svg#level3"></use>
            </svg>
            ${child.name}
            <div class='header__right-count'>${child.count}</div>
           `;
        }
      }
      if (child.isOpen) {
        elem.classList = 'header__right-info';
      } else {
        elem.classList = 'header__right-info collapsed';
      }
      const container = document.createElement('div');
      container.classList = 'header__right-container';
      const containerCollaps = document.createElement('div');
      containerCollaps.classList = 'header__right-container-collapse';
      if (child.array.length != 0) {
        elem.setAttribute('data-bs-toggle', 'collapse');
        elem.setAttribute('href', `#headerAccardion${child.level}${indexElement}`);
        elem.setAttribute('role', 'button');
        elem.setAttribute('aria-expanded', 'false');
        elem.setAttribute('aria-controls', `#headerAccardion${child.level}${indexElement}`);
        container.setAttribute('id', `headerAccardion${child.level}${indexElement}`);
        if (child.isOpen) {
          container.classList = 'collapse multi-collapse show';
        } else {
          container.classList = 'collapse multi-collapse';
        }

        mainBlock.appendChild(elem);
        mainBlock.appendChild(container);
        container.appendChild(containerCollaps);
        if (child.level == 1) {
          rightCase.appendChild(mainBlock);
        } else {
          containerCopy.appendChild(mainBlock);
        }
        htmlJson(child.array, containerCollaps, indexElement);
      } else {
        containerCopy.appendChild(elem);
      }
    });
  }
};

if (rightCase) {
  jsonHeader?.forEach((item, index) => {
    htmlJson(item, '', index);
  });
}

const slidesBig = new Swiper('.slidesBig', {
  modules: [Navigation],
  slidesPerView: 1,
  spaceBetween: 14,
  navigation: {
    nextEl: '.slides-big__button--next',
    prevEl: '.slides-big__button--prev',
  },
});

const fileUploads = document.querySelectorAll('.js-file-upload');

const handleDrop = (e) => {
  const dt = e.dataTransfer;
  const files = dt.files;
  const fileArray = [...files];
  console.log(files);
  console.log(fileArray);
};

if (fileUploads) {
  fileUploads.forEach((fileUpload) => {
    const fileUploadActive = () => fileUpload.classList.add('custom-file-upload--active');

    const fileUploadInitial = () => fileUpload.classList.remove('custom-file-upload--active');

    const prevents = (e) => e.preventDefault();

    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
      fileUpload.addEventListener(eventName, prevents);
    });

    ['dragenter', 'dragover'].forEach((eventName) => {
      fileUpload.addEventListener(eventName, fileUploadActive);
    });

    ['dragleave', 'drop'].forEach((eventName) => {
      fileUpload.addEventListener(eventName, fileUploadInitial);
    });

    fileUpload.addEventListener('drop', handleDrop);
  });
}

const exampleEl = document.querySelectorAll('.js-pro');

if (exampleEl) {
  exampleEl.forEach((el) => {
    new bootstrap.Tooltip(el, {});
  });
}





const slidesItems = document.querySelectorAll('.swiper-items-img')


if (slidesItems) {
  slidesItems.forEach(item => {
    new Swiper(item.querySelector('.swiper'), {
      modules: [Navigation, Pagination],
      slidesPerView: 2,
      spaceBetween: 14,
      breakpoints: {
        576: {
          slidesPerView: 4,
        },
        768: {
          slidesPerView: 3,
        },
        1024: {
          slidesPerView: 5,
        },
        1440: {
          spaceBetween: 36,
          slidesPerView: 6,
        }
      },
      navigation: {
        nextEl: item.querySelector('.swiper-items-img__btn--next'),
        prevEl: item.querySelector('.swiper-items-img__btn--prev'),
      },
      pagination: {
        clickable: true,
        el: item.querySelector('.swiper-items-img__pagination'),
      }
    });
  })
}

const swiperCards = document.querySelectorAll('.swiper-cards')


if (swiperCards) {
  swiperCards.forEach(item => {
    new Swiper(item.querySelector('.swiper'), {
      modules: [Navigation, Pagination],
      slidesPerView: 'auto',
      spaceBetween: 24,
      breakpoints: {
        // 576: {
        //   slidesPerView: 3,
        // },
        768: {
          slidesPerView: 2,
        },
        1024: {
          slidesPerView: 4,
        },
        1440: {
          spaceBetween: 24,
          slidesPerView: 5,
        }
      },
      navigation: {
        nextEl: item.querySelector('.swiper-cards__btn--next'),
        prevEl: item.querySelector('.swiper-cards__btn--prev'),
      },
      pagination: {
        clickable: true,
        el: item.querySelector('.swiper-cards__pagination'),
      }
    });
  })
}

const swiperProgramms = document.querySelector('.swiper-programms')


if (swiperProgramms) {
  new Swiper(swiperProgramms.querySelector('.swiper'), {
    modules: [Pagination],
    slidesPerView: 1.1,
    spaceBetween: 20,
    pagination: {
      clickable: true,
      el: swiperProgramms.querySelector('.swiper-programms__pagination'),
    }
  });
}

const swiperBanners = document.querySelector('.swiper-banners')


if (swiperBanners) {
  new Swiper(swiperBanners.querySelector('.swiper'), {
    modules: [Pagination],
    slidesPerView: 1,
    spaceBetween: 20,
    pagination: {
      clickable: true,
      el: swiperBanners.querySelector('.swiper-banners__pagination'),
    }
  });
}

const swiperDatabaseCards = document.querySelector('.swiper-database-cards')


if (swiperDatabaseCards) {
  new Swiper(swiperDatabaseCards.querySelector('.swiper'), {
    modules: [Pagination],
    slidesPerView: 1,
    spaceBetween: 20,
    pagination: {
      clickable: true,
      el: swiperDatabaseCards.querySelector('.swiper-database-cards__pagination'),
    }
  });
}
